<template>
  <div class="lk-nav__header">
    <div class="lk-nav__title">{{ user.name }}</div>
    <div class="lk-nav__subtitle">{{ user.email }}</div>
  </div>
</template>
<script>
import { useStore } from 'vuex'
import { computed } from 'vue'
export default {
  setup () {
    const store = useStore()
    const user = computed(() => {
      return store.getters['auth/user']
    })
    return {
      user
    }
  }
}
</script>
