<template>
  <section class="section-feedback mb-0">
    <div class="section-feedback__inner">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h3 class="title title--secondary text-uppercase">Остались вопросы?</h3>
            <p class="mt-2">Оставляй телефон - перезвоним!</p>
            <form action="" class="section-feedback__form feedback-form">
              <input type="text" class="feedback-form__input form-control" placeholder="38 (___) __ __ ___">
              <div class="input-error">вы чего же сделали такое</div>
              <button class="btn btn--blue feedback-form__btn" type="submit">Перезвоните мне</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {}
</script>
