<template>
  <ul v-if="menu.length > 0" class="lk-nav__menu">
    <li v-for="(item, index) in menu" :key="index" class="lk-nav__menu-item" :class="{'active': currentRoute === item.route }">
      <router-link :to="{ name: item.route }" class="lk-nav__menu-link">
        <span v-html="item.icon"></span>
        <span>{{ item.name }}</span>
      </router-link>
    </li>
    <li class="lk-nav__menu-item">
      <a href="" class="lk-nav__menu-link">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.1527 2.92356C12.7495 2.76949 12.298 2.97117 12.1439 3.37422C11.9898 3.77719 12.1916 4.22887 12.5945 4.38297C15.3561 5.43902 17.2656 8.10094 17.2656 11.1719C17.2656 15.1782 14.0063 18.4375 10 18.4375C5.99371 18.4375 2.73438 15.1782 2.73438 11.1719C2.73438 8.09926 4.64484 5.43883 7.4052 4.38309C7.8082 4.22895 8.00992 3.77727 7.85582 3.3743C7.70164 2.97129 7.24988 2.76961 6.84703 2.92367C3.48379 4.21004 1.17188 7.44891 1.17188 11.1719C1.17188 16.0516 5.1207 20 10 20C14.8798 20 18.8281 16.0512 18.8281 11.1719C18.8281 7.44727 16.5148 4.20934 13.1527 2.92356Z" fill="#F42E46"/>
          <path d="M10 0C9.56852 0 9.21875 0.349766 9.21875 0.78125V8.43168C9.21875 8.86316 9.56852 9.21293 10 9.21293C10.4315 9.21293 10.7812 8.86312 10.7812 8.43168V0.78125C10.7812 0.349766 10.4315 0 10 0Z" fill="#F42E46"/>
        </svg>
        <span @click="logout" id="logout">Выйти из аккаунта</span>
      </a>
    </li>
  </ul>
</template>
<script>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
export default {
  setup () {
    const { t } = useI18n()
    const store = useStore()
    const router = useRouter()
    const menu = [
      { name: t('information'), route: 'account', icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.99848 7.13965C9.50168 7.13965 9.14844 7.34941 9.14844 7.65852V11.8645C9.14844 12.1294 9.50172 12.3944 9.99848 12.3944C10.4732 12.3944 10.8595 12.1294 10.8595 11.8645V7.65852C10.8595 7.34941 10.4732 7.13965 9.99848 7.13965Z" fill="#24ABE2"/><path d="M9.99898 4.54529C9.49117 4.54529 9.09375 4.90958 9.09375 5.32908C9.09375 5.74857 9.49117 6.12392 9.99898 6.12392C10.4958 6.12392 10.8932 5.74857 10.8932 5.32908C10.8932 4.90962 10.4957 4.54529 9.99898 4.54529Z" fill="#24ABE2"/><path d="M10 0C5.24992 0 1.40625 3.84172 1.40625 8.58879C1.40625 12.465 3.95832 15.6836 7.3991 16.7766L9.35348 19.6574C9.49887 19.8717 9.74102 20 10 20C10.259 20 10.5011 19.8717 10.6465 19.6574L12.6009 16.7766C16.0432 15.683 18.5938 12.4633 18.5938 8.58879C18.5938 3.83871 14.747 0 10 0ZM11.9002 15.3554C11.7223 15.4052 11.5678 15.5164 11.4641 15.6692L10 17.8272L8.53598 15.6693C8.43223 15.5164 8.27773 15.4052 8.0998 15.3555C5.17098 14.5365 2.96875 11.843 2.96875 8.58879C2.96875 4.71445 6.12297 1.5625 10 1.5625C13.877 1.5625 17.0312 4.71445 17.0312 8.58879C17.0312 11.8462 14.8258 14.5374 11.9002 15.3554Z" fill="#24ABE2"/></svg>' },
      { name: t('orders'), route: 'orders', icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18.6328 0C17.9081 0 2.08789 0 1.36719 0C0.613281 0 0 0.613281 0 1.36719V18.6328C0 19.3867 0.613281 20 1.36719 20H18.6328C19.3867 20 20 19.3867 20 18.6328V1.36719C20 0.613281 19.3867 0 18.6328 0ZM7.23086 1.17188H12.7691V4.48477L10.2184 3.46055C10.0785 3.4043 9.92148 3.4043 9.78164 3.46055L7.23086 4.48477V1.17188ZM18.8281 18.6328C18.8281 18.7406 18.7406 18.8281 18.6328 18.8281H1.36719C1.25937 18.8281 1.17188 18.7406 1.17188 18.6328V1.36719C1.17188 1.25937 1.25937 1.17188 1.36719 1.17188H6.05898V5.35156C6.05898 5.76445 6.4768 6.05008 6.86328 5.89531L10 4.63594L13.1367 5.89531C13.5238 6.05016 13.941 5.76379 13.941 5.35156V1.17188H18.6328C18.7406 1.17188 18.8281 1.25937 18.8281 1.36719V18.6328Z" fill="#24ABE2"/> <path d="M8.22625 11.1328H3.57422C3.25062 11.1328 2.98828 11.3952 2.98828 11.7188C2.98828 12.0423 3.25062 12.3047 3.57422 12.3047H8.22625C8.54984 12.3047 8.81219 12.0423 8.81219 11.7188C8.81219 11.3952 8.54984 11.1328 8.22625 11.1328Z" fill="#24ABE2"/> <path d="M6.69922 14.2448H3.57422C3.25063 14.2448 2.98828 14.5071 2.98828 14.8307C2.98828 15.1543 3.25063 15.4166 3.57422 15.4166H6.69922C7.02281 15.4166 7.28516 15.1543 7.28516 14.8307C7.28516 14.5071 7.02281 14.2448 6.69922 14.2448Z" fill="#24ABE2"/></svg>' },
      { name: t('settlement'), route: 'settlement', icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M17.0711 2.92891C15.1823 1.04016 12.6711 0 10 0C7.32891 0 4.81766 1.04016 2.92891 2.92891C1.0402 4.81766 0 7.32891 0 10C0 12.6711 1.0402 15.1823 2.92891 17.0711C4.81766 18.9598 7.32891 20 10 20C12.6711 20 15.1823 18.9598 17.0711 17.0711C18.9598 15.1823 20 12.6711 20 10C20 7.32891 18.9598 4.81766 17.0711 2.92891ZM10 18.8281C5.13215 18.8281 1.17188 14.8679 1.17188 10C1.17188 5.13215 5.13215 1.17188 10 1.17188C14.8679 1.17188 18.8281 5.13215 18.8281 10C18.8281 14.8679 14.8679 18.8281 10 18.8281Z" fill="#24ABE2"/><path d="M10.6263 9.41414H9.37106C8.65594 9.41414 8.07418 8.83239 8.07418 8.11731C8.07418 7.40219 8.65598 6.82043 9.37106 6.82043H11.8815C12.2051 6.82043 12.4674 6.55809 12.4674 6.2345C12.4674 5.9109 12.2051 5.64856 11.8815 5.64856H10.5846V4.35168C10.5846 4.02809 10.3223 3.76575 9.99868 3.76575C9.67508 3.76575 9.41274 4.02809 9.41274 4.35168V5.64856H9.3711C8.00981 5.64856 6.90234 6.75606 6.90234 8.11731C6.90234 9.47856 8.00985 10.586 9.3711 10.586H10.6263C11.3414 10.586 11.9232 11.1678 11.9232 11.8829C11.9232 12.598 11.3414 13.1798 10.6263 13.1798H8.1159C7.79231 13.1798 7.52996 13.4421 7.52996 13.7657C7.52996 14.0893 7.79231 14.3516 8.1159 14.3516H9.41274V15.6486C9.41274 15.9722 9.67508 16.2345 9.99868 16.2345C10.3223 16.2345 10.5846 15.9722 10.5846 15.6486V14.3516H10.6263C11.9875 14.3516 13.095 13.2441 13.095 11.8829C13.095 10.5216 11.9875 9.41414 10.6263 9.41414Z" fill="#24ABE2"/></svg>' },
      { name: t('my_refunds'), route: 'refunds', icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.9998 3.16406C8.80273 3.16406 7.82879 4.13797 7.82879 5.33504V6.41348H5.8548L5.37109 15.303H14.6284L14.1447 6.41348H12.1707V5.33504C12.1708 4.13797 11.1969 3.16406 9.9998 3.16406ZM13.3911 14.1312H6.60852L6.96469 7.58539H13.035L13.3911 14.1312ZM9.9998 4.33594C10.5507 4.33594 10.9989 4.78414 10.9989 5.33504V6.41348H9.00066V5.33504C9.0007 4.78414 9.44891 4.33594 9.9998 4.33594Z" fill="#24ABE2"/><path d="M18.4489 4.65129L19.7914 4.88668L19.9937 3.73242L16.6218 3.14125L16.0306 6.51328L17.1848 6.71566L17.4417 5.25078C18.3407 6.66137 18.8281 8.31508 18.8281 10C18.8281 12.3581 17.9098 14.575 16.2424 16.2424C14.575 17.9098 12.3581 18.8281 10 18.8281C7.64187 18.8281 5.425 17.9098 3.75758 16.2424C2.09016 14.575 1.17188 12.3581 1.17188 10C1.17188 7.64191 2.09016 5.425 3.75758 3.75758C5.425 2.0902 7.64191 1.17188 10 1.17188C11.7025 1.17188 13.355 1.6573 14.7787 2.5757L15.4139 1.59094C13.8005 0.550156 11.9284 0 10 0C7.32891 0 4.8177 1.04016 2.92895 2.92891C1.0402 4.8177 0 7.32891 0 10C0 12.6711 1.0402 15.1823 2.92895 17.0711C4.8177 18.9598 7.32891 20 10 20C12.6711 20 15.1823 18.9598 17.0711 17.0711C18.9598 15.1823 20 12.6711 20 10C20 8.10391 19.4549 6.24254 18.4489 4.65129Z" fill="#24ABE2"/><path d="M8.19141 10.2723H11.8112V11.4442H8.19141V10.2723Z" fill="#24ABE2"/></svg>' },
      { name: t('help'), route: 'help', icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 13.5352C20 10.62 18.0357 8.07297 15.2658 7.30545C15.0917 3.24768 11.7366 0 7.63672 0C3.42575 0 0 3.42575 0 7.63672C0 9.00909 0.365295 10.3453 1.05896 11.5184L0.0280762 15.2452L3.75504 14.2145C4.83337 14.8521 6.0498 15.2113 7.3053 15.2655C8.07266 18.0356 10.6198 20 13.5352 20C14.6988 20 15.8304 19.6901 16.8246 19.1013L19.9718 19.9718L19.1013 16.8246C19.6901 15.8304 20 14.6988 20 13.5352ZM3.93997 12.9474L1.70883 13.5646L2.32605 11.3335L2.18536 11.1134C1.52237 10.0761 1.17188 8.8739 1.17188 7.63672C1.17188 4.07196 4.07196 1.17188 7.63672 1.17188C11.2015 1.17188 14.1016 4.07196 14.1016 7.63672C14.1016 11.2015 11.2015 14.1016 7.63672 14.1016C6.39954 14.1016 5.19745 13.7511 4.16 13.0881L3.93997 12.9474ZM18.2912 18.2912L16.6356 17.8331L16.4145 17.977C15.5574 18.5338 14.5616 18.8281 13.5352 18.8281C11.2439 18.8281 9.2305 17.3466 8.51715 15.2223C12.0258 14.8178 14.8178 12.0258 15.2225 8.517C17.3466 9.2305 18.8281 11.2439 18.8281 13.5352C18.8281 14.5616 18.5338 15.5574 17.977 16.4145L17.8331 16.6356L18.2912 18.2912Z" fill="#24ABE2"/><path d="M7.05078 10.5859H8.22266V11.7578H7.05078V10.5859Z" fill="#24ABE2"/><path d="M8.80859 5.85938C8.80859 6.19217 8.67309 6.49933 8.42712 6.7244L7.05078 7.98416V9.41406H8.22266V8.50021L9.21829 7.58896C9.70261 7.14569 9.98047 6.51535 9.98047 5.85938C9.98047 4.56696 8.92914 3.51562 7.63672 3.51562C6.3443 3.51562 5.29297 4.56696 5.29297 5.85938H6.46484C6.46484 5.21317 6.99051 4.6875 7.63672 4.6875C8.28293 4.6875 8.80859 5.21317 8.80859 5.85938Z" fill="#24ABE2"/></svg>' },
      { name: t('wishlist'), route: 'wishlist', icon: '<svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.813 1.88009C19.6406 0.683495 17.9978 0.00264928 16.2766 0.000125296C14.554 0.00201829 12.9094 0.682504 11.7352 1.87933L11.335 2.28086L10.9348 1.87933C8.60484 -0.501833 4.6831 -0.638579 2.17547 1.57383C2.06435 1.67191 1.95703 1.77377 1.85374 1.87933C-0.617913 4.4108 -0.617913 8.32307 1.85374 10.8545L10.7469 19.7598C11.0546 20.0682 11.5673 20.0814 11.8921 19.7892C11.9027 19.7797 11.9131 19.7699 11.9232 19.7598L20.8131 10.8545C23.2845 8.32334 23.2845 4.41129 20.813 1.88009ZM19.6409 9.79532H19.64L11.335 18.1129L3.02919 9.79532C1.14099 7.86104 1.14099 4.87206 3.02919 2.93778C4.7439 1.17306 7.64062 1.06241 9.4991 2.69061C9.58934 2.76966 9.67615 2.8521 9.75941 2.93778L10.7469 3.927C11.0723 4.23403 11.5977 4.23403 11.9232 3.927L12.9106 2.93854C14.6253 1.17383 17.5221 1.06318 19.3805 2.69137C19.4708 2.77043 19.5576 2.85286 19.6409 2.93854C21.5455 4.87589 21.5594 7.87033 19.6409 9.79532Z" fill="#24ABE2"/></svg>' },
      { name: t('viewed_products'), route: 'viewed', icon: '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0)"><path d="M9.99992 10.7031C8.06145 10.7031 6.48438 12.2802 6.48438 14.2188C6.48438 16.1573 8.06145 17.7344 9.99992 17.7344C11.9384 17.7344 13.5155 16.1573 13.5155 14.2188C13.5155 12.2802 11.9384 10.7031 9.99992 10.7031ZM9.99992 16.1719C8.92297 16.1719 8.04684 15.2957 8.04684 14.2188C8.04684 13.1418 8.92297 12.2656 9.99992 12.2656C11.0769 12.2656 11.953 13.1418 11.953 14.2188C11.953 15.2957 11.0769 16.1719 9.99992 16.1719Z" fill="#24ABE2"/><path d="M19.916 12.3682C19.0543 10.6616 17.6888 9.21586 15.9663 8.18535L18.081 6.07059C18.3861 5.76551 18.3861 5.27086 18.081 4.96574C17.7759 4.66066 17.2813 4.66066 16.9762 4.96574L14.4953 7.44672C13.326 6.96277 12.0709 6.67168 10.7812 6.58789V3.04688C10.7812 2.61539 10.4315 2.26562 9.99998 2.26562C9.5685 2.26562 9.21873 2.61539 9.21873 3.04688V6.58785C7.92908 6.67164 6.67401 6.96273 5.50471 7.44668L3.02381 4.9657C2.71869 4.66059 2.22401 4.66059 1.91893 4.9657C1.61389 5.27078 1.61389 5.76543 1.91897 6.07055L4.03369 8.18531C2.31112 9.21582 0.945646 10.6616 0.0840054 12.3682C-0.110448 12.7534 0.0441617 13.2232 0.429318 13.4177C0.814357 13.6122 1.2844 13.4576 1.47881 13.0724C2.92795 10.2022 6.1574 8.125 10 8.125C13.8455 8.125 17.0733 10.2047 18.5212 13.0725C18.716 13.4583 19.1861 13.6119 19.5707 13.4177C19.9559 13.2232 20.1105 12.7534 19.916 12.3682Z" fill="#24ABE2"/></g><defs><clipPath id="clip0"><rect width="20" height="20" fill="white"/></clipPath></defs></svg>' }
    ]
    const currentRoute = computed(() => {
      return router.currentRoute.value.name
    })
    async function logout () {
      await store.dispatch('auth/logout')
      router.push({ name: 'login' })
    }
    return {
      menu,
      currentRoute,
      logout
    }
  }
}
</script>
